import AspectWrapper from '@sportson/core-web/components/AspectWrapper';
import FullWidth from '@sportson/core-web/App/layouts/FullWidth';
import ProductsPromotionBlock, {
    type WordpressProductsPromotionBlock,
} from 'libs/wordpress/content/Blocks/ProductsPromotion';
import { type WordpressStandardModuleSettings } from '../../types/contentSystem/groups';
import { transformBackgroundColor } from '../../utils/transformBackground';

export interface ProductsPromotionColumnsModuleProps extends Record<string, unknown> {
    data: {
        data: WordpressProductsPromotionBlock;
        moduleSettings: WordpressStandardModuleSettings;
    };
    layout: 'products_promotion_columns';
    isHero?: boolean;
}

const ProductsPromotionColumnsModule = ({ data, isHero, layout, ...rest }: ProductsPromotionColumnsModuleProps) => {
    const { moduleSettings, data: blockData } = data;
    const { backgroundColor } = moduleSettings;

    // @TODO Implement background, spacing, ratio and visibility
    const moduleBgColor = transformBackgroundColor(backgroundColor).backgroundColor;
    return (
        <FullWidth
            gridColumn={isHero ? 'full-width' : ''}
            mt={isHero ? '' : 'var(--module-margin-top-default)'}
            mb={isHero ? 'var(--module-margin-bottom-small)' : 'var(--module-margin-bottom-default)'}
            p={['40px 12px', null, '24px 16px', null, '40px']}
            backgroundColor={moduleBgColor || 'var(--color-transparent)'}
        >
            <ProductsPromotionBlock data={blockData} layout={layout} {...rest} />
        </FullWidth>
    );
};

export default ProductsPromotionColumnsModule;
